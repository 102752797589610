import React from "react";

// import { Header } from "./components/Header";

import { Footer } from "./components/Footer";
// import { Home } from "./pages/Home";
// import { Noticias } from "./pages/Noticias";
// import { PartnersPage } from "./pages/PartnersPage";

// import Sobre from "./pages/Sobre";
// import Onde from "./pages/Onde";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Campaign } from "./pages/Campaign";
import { Maintenance } from "./pages/Maintenance";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Maintenance />} />
          {/* <Route path="/quem-somos" element={<Sobre />} />
          <Route path="/onde-estamos" element={<Onde />} />
          <Route path="/noticias/*" element={<Noticias />} />
          <Route path="/parceiros" element={<PartnersPage />} />
          <Route path="/safrapremiada" element={<Campaign />} /> */}
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
