import "aos/dist/aos.css";

import "../styles/maintenance.css";
import "../styles/global.css";

import Lottie from "react-lottie";
import * as animationData from "../tractor.json";

export function Maintenance() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div id="maintenance">
      <div className="maintenance-text">
        <h1>Em manutenção</h1>
        <h2>Em breve estaremos de volta!</h2>
      </div>
      <Lottie options={defaultOptions} height={300} width={300} />
    </div>
  );
}
