
import { FaInstagram, FaYoutube, FaMapMarkedAlt, FaPhoneAlt, FaMapSigns } from "react-icons/fa";

import './styles.css'

export function Footer() { 
    return (
      <div>
        <div className="divider-1"> 

        </div> 
        <footer>
          <div className="main-footer">
            <div className="footer-grid">
              <img src="/assets/images/logo_centralizada.webp" alt=""/>
            </div>
            <div className="addresses">
              <div className="address matriz">
                <h3>Matriz: Paripiranga - BA</h3>
                <p><FaMapMarkedAlt/>  48430-000</p>
                <p><FaMapSigns/>  Trav. Salustiano Domingues de Santana</p>
                <p><FaPhoneAlt/>  (75) 9 3279-2922</p>
              </div>
              <div className="address matriz"> 
                <h3>Filial: Poço Verde - SE</h3>
                <p><FaMapMarkedAlt/>  49490-000</p>
                <p><FaMapSigns/>  Av. Simão Dias, 829, Bairro Fazendinha</p>
                <p><FaPhoneAlt/>  (75) 9 9897-4704</p>
              </div>
            </div> 
            <div className="footer-grid">
              <div className="social-wrapper">
                <div className="text">Acompanhe-nos</div>
                <div className="social-media">
                  <a className="instagram" href="https://www.instagram.com/aparecida.agricola/" target="_blank" rel="noreferrer">
                    <FaInstagram color= "white" size="50%" background="pink"/>
                  </a>
                  <a className="youtube" href="https://www.youtube.com/channel/UCT9DPUwryimx6JhpLF8KVJA" target="_blank" rel="noreferrer">
                    <FaYoutube color= "white" size="50%" background="pink"/>
                  </a>
                </div>
              </div>
            </div>
          </div> 
        </footer>
          <div>
            <a className="whatsapp" href="https://wa.me/5575998393187/?text=Olá, visitei o site da empresa e gostaria de saber mais sobre vocês" target="_blank" rel="noreferrer" title="Entrar em contato"><img src="/assets/images/whatsapp_icon.svg" alt=""/>
            </a>
          </div>
        <span></span>
      </div>
    );
  } 
  